@import "../../styles/variables.scss";
@import "../../styles/media.scss";

.SidebarLayout {
  display: grid;
  grid-template-columns:
    1fr
    auto;
  grid-template-areas: "content sidebar";

  position: relative;
  height: 100%;
  width: 100%;

  @include lt(large) {
    grid-template-columns:
      1fr
      auto;
  }

  @include lt(small) {
    display: inherit;
  }
}

.SidebarLayout-logo-outer-link {
  position: absolute;
  left: 3%;
  top: $spacing--base * 7;
  @include lt(large) {
    display: none;
  }
}

.SidebarLayout-MainContent {
  grid-area: content;
  overflow: auto;
  display: flex;
  justify-content: center;
  @include lt(small) {
    overflow: initial;
    min-height: 100%;
  }
}

.SidebarLayout-MainContentInner {
  padding-top: $spacing--base * 7;

  max-width: 700px;
  width: 67%;
  @include lt(large) {
    width: 76%;
  }
  @include lt(small) {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding-top: 0;
  }
}

.SidebarLayout-MainContentInner {
  grid-area: contentInner;
}

.SidebarLayout-Sidebar {
  min-width: 300px;
  width: 28vw;
  max-width: 420px;
  grid-area: sidebar;
  overflow: auto;
  background-color: $palette--tint-1-grey;
  border-left: 1px solid $palette--tint-3-grey;
  @include lt(small) {
    display: none;
  }
}
