@import "../../styles/media.scss";
@import "../../styles/variables/spacing";

.ResetPasswordModal {
  .CardModal-card {
    width: 400px;
    height: fit-content;
    margin: auto;

    .InputText-input {
      height: 46px;
    }

    .ResetPasswordForm-inputWrapper {
      position: relative;
    }

    .CardModal-cardBodyContainer {
      padding: 0 45px;
    }
  }
}

.CardModal-drawerChildren .CardModal-cardFooterContainer {
  border-top: none;
  padding: 12px 0px 25px;
}

.ResetPasswordForm-inputText {
  position: relative;
}

.ResetPasswordForm-showPasswordToggle {
  position: absolute;
  top: $spacing--small;
  right: $spacing--small;

  @include lt(medium) {
    top: $spacing--small + 1px;
    right: $spacing--small + 1px;
  }
}
