.AuthPageSidebar {
  position: relative;
  min-height: 100%;
  display: flex;
  overflow: hidden; // Hide scrollbar
}

.AuthPageSidebar-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 66%;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(
      to top,
      $palette--tint-1-grey 2%,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

.AuthPageSidebar-promotionOuter {
  align-self: flex-end;
  position: relative;
  z-index: 10;
  padding: 0 $spacing--medium $spacing--small;

  &::after {
    content: "";
    position: absolute;
    top: -180px;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
    background: linear-gradient(0deg, #f7f9fa 0%, rgba(247, 249, 250, 0) 100%),
      linear-gradient(0deg, #f7f9fa 0%, rgba(247, 249, 250, 0) 100%);
  }
}

.AuthPageSidebar-promotionItem {
  margin-bottom: $spacing--base * 4;
}

.AuthPageSidebar-promotionTitle {
  font-size: $font-size--e;
}

.AuthPageSidebar-promotionDescription {
  @include typo--body-2;
}
