@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";
@import "../../../../styles/icons.scss";
@import "../../../../styles/media.scss";
@import "../../../../styles/animation.scss";
@import "./AuthPageSidebar.scss";

// Since auth page's layout extends the grid to the full height of the viewport
// we need to remove SidebarLayout's "padding-top" property.
// Otherwise this "padding-top" increases the page height. Therefore the scrollbar appears.
.AuthPage-SidebarLayout .SidebarLayout-MainContentInner {
  padding-top: initial;
}

.AuthPage {
  width: 100%;
}

.AuthPage-container {
  grid-area: container;

  @include lt(small) {
    padding: 0 $spacing--base * 4;
  }
}

.AuthPage-containerContent {
  display: grid;
  width: 100%;
  min-height: 100vh;
  grid-template-rows: max-content 45px 1fr;
  grid-template-areas:
    "header"
    "."
    "authComponent";

  @include gt(medium) {
    grid-template-rows: max-content minmax(60px, 7vh) 1fr;
    padding-top: $spacing--base * 7;
  }
}

.AuthPage-containerContent .AuthPage-header {
  grid-area: header;
}

.AuthComponentWrapper {
  grid-area: authComponent;
}

.AuthPage-containerContent .AuthComponent {
  height: 100%;

  @include gt(xxsmall) {
    padding-top: 10vh;
  }
}

.AuthPage-coverImage {
  grid-area: header;
  display: inherit;
  width: 100%;
  height: 20vh;
  margin-bottom: $spacing--base * 7;

  @include gt(medium) {
    display: none;
  }
}

.AuthPage-header {
  text-align: center;

  @include gt(medium) {
    text-align: left;
  }
}

.AuthPage-header-title {
  font-family: $primary-font-stack;
  font-weight: $font-weight--bold;
  font-size: 24px;
  line-height: $line-height--small;
  margin-bottom: $spacing--base * 2;
  word-wrap: break-word;

  @include gt(medium) {
    margin-bottom: $spacing--base;
  }
}

.AuthPage-header-description {
  font-family: $primary-font-stack;
  font-weight: $font-weight--medium;
  font-size: $font-size--f;
  line-height: 21px;
  color: $palette--dark-grey;
  word-wrap: break-word;

  @include gt(medium) {
    max-width: 450px;
  }
}

.StepSignupHeader .AuthPage-header-description {
  max-width: 305px;

  @include lt(medium) {
    margin: $spacing--base auto;
  }
}
