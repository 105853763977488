@import "../../../styles/variables.scss";
@import "../../../styles/media.scss";
@import "../../../styles//animation.scss";

.LoginCode {
  animation: loginCodeFadeInDown $login-transition-timing
    $login-transition-function;
}

.LoginCode-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
  margin-bottom: 15px;
  border-bottom: 1px solid $palette--tint-3-grey;
}

.LoginCode-submit-container {
  @include lt(small) {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 20px;
    background: white;
    width: 100%;
  }
}

.LoginCode .LoginCode-submitButton {
  width: 100%;

  @include gt(small) {
    width: 100%;
    padding: 0 30px;
  }

  // small arrow version of button
  @include gt(medium) {
    width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
  }

  @include gt(large) {
    width: auto;
    padding: 0 30px;
  }
}

.LoginCode-submit-text {
  display: inline;
  @include gt(medium) {
    display: none;
  }
  @include gt(large) {
    display: inline;
  }
}

.LoginCode-submit-icon {
  display: none;
  @include gt(medium) {
    display: inline;
  }
  @include gt(large) {
    display: none;
  }
}

.LoginCode .ErrorIndicator.ErrorIndicator--invalid .ErrorIndicator-message {
  color: $color-error;
}

.LoginCode .ErrorIndicator.ErrorIndicator--required .ErrorIndicator-message {
  color: $color-required;
}

.LoginCode .ErrorIndicator.ErrorIndicator--invalid .CodeInput-input,
.LoginCode .ErrorIndicator.ErrorIndicator--required .CodeInput-input {
  animation: shake 0.5s linear;
}

@keyframes loginCodeFadeInDown {
  from {
    opacity: 0;
    transform: translateY(-40%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
