@import "../../../styles/variables.scss";
@import "../../../styles/media.scss";

.AuthComponentButton {
  display: inline-flex;
  align-items: center;
  line-height: $line-height--medium;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: none;
}

.AuthComponentButton-icon {
  margin-right: 10px;
}

.AuthComponentButton-label {
  color: $color-2019--font-secondary;
  font-size: $font-size--g;
  font-weight: $font-weight--medium;
  line-height: $line-height--medium;
  font-family: $primary-font-stack;
}
