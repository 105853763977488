@import "../../styles/variables/colors.scss";
@import "../../styles/variables/typography.scss";

.CardModal-card {
  width: 500px;
  background: white;
  box-shadow: -4px 0px 30px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-height: 100%;
}

.CardModal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(36, 39, 46, 0.35);
  z-index: 200;

  opacity: 0;
  transition: opacity 300ms;

  &--autoHeight {
    align-items: center;
  }

  .CardModal-card {
    opacity: 0;
    transform: translateY(100px);
    transition:
      opacity 300ms,
      transform 300ms;
  }

  &.enter {
    opacity: 0;

    .CardModal-card {
      opacity: 0;
      transform: translateY(100px);
      transition-delay: 100ms;
    }
  }
  &.enter-active {
    opacity: 1;

    .CardModal-card {
      opacity: 1;
      transform: translateY(0px);
      transition-delay: 100ms;
    }
  }
  &.enter-done {
    opacity: 1;

    .CardModal-card {
      opacity: 1;
      transform: translateY(0px);
      transition-delay: 100ms;
    }
  }
  &.exit {
    opacity: 1;

    .CardModal-card {
      opacity: 1;
      transform: translateY(0px);
      transition-delay: 0ms;
    }
  }
  &.exit-active {
    opacity: 0;

    .CardModal-card {
      opacity: 0;
      transform: translateY(100px);
      transition-delay: 0ms;
    }
  }
}

.CardModal-closeButtonContainer {
  display: inline-block;
  line-height: 0;
  padding-left: 10px;
  position: absolute;
  left: 100%;
}

.CardModal-closeButton {
  width: 36px;
  height: 36px;
  outline: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.1);
  border: none;
  cursor: pointer;
}

.CardModal-cardHeaderContainer {
  max-width: 310px;
  margin: 0 auto;
  text-align: center;
  padding-top: 35px;
  padding-bottom: 20px;
}

.CardModal-headerIconContainer {
  background: $palette--pale-purple;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &--danger {
    background: $palette--pale-red;
  }

  &--gradient {
    background: $palette--gradient-purple;
  }
}

.CardModal-headerTitle {
  padding-top: 15px;
  font-family: $primary-font-stack;
  font-weight: $font-weight--bold;
  line-height: 1.35;
  font-size: $font-size--c;

  &--small {
    font-size: $font-size--d;
  }
}

.CardModal-headerSubtitle {
  font-size: $font-size--f;
  font-weight: $font-weight--medium;
  padding-top: 5px;
  color: $color-2019--font-secondary;
}

.CardModal-scrollContainer {
  width: 100%;
  flex: 1;
  overflow: auto;
}

.CardModal-cardBodyContainer {
  padding: 0 45px 35px;
}

.CardModal-cardFooterContainer {
  padding: 25px 0;
}

.CardModal-buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 45px;
}

.CardModal-buttonsContainer > * + * {
  margin-left: 20px;
}

.CardModal-drawerChildren {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 10px;
}

.CardModal-drawerChildren {
  height: auto;
  display: block;
  overflow-y: auto;
  padding-bottom: 50px;
}

.CardModal-drawerChildren .CardModal-cardFooterContainer {
  border-top: 1px solid $palette--tint-2-grey;
  background: #ffffff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 10px 0;
}

.CardModal-drawerChildren .CardModal-buttonsContainer {
  padding: 0 20px;
}

.CardModal-drawerChildren .CardModal-buttonsContainer > * + * {
  margin-left: 15px;
}

.CardModal-drawerChildren .CardModal-cardBodyContainer {
  padding: 0 20px 20px;
}

.card-modal-open {
  body {
    padding-right: var(--scrollbarWidth);
  }

  .CardModal-backdrop {
    padding-right: calc(var(--scrollbarWidth) + 20px);
  }

  // If .StickyHeader is removed from here, also remove comment
  // from src/components/StickyHeader/StickyHeader.scss.
  .StickyHeader {
    padding-right: var(--scrollbarWidth);
  }
}
