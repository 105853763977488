@import "../../styles/variables/colors.scss";
@import "../../styles/variables/spacing.scss";
@import "../../styles/variables/typography.scss";

.ListingCostSummary-extraInfo {
  display: flex;
  margin-top: $spacing--base * 4;
}

.ListingCostSummary-extraInfo-icon {
  margin-right: 20px;
}

.ListingCostSummary-extraInfo-text {
  font-family: $primary-font-stack;
  font-weight: $font-weight--medium;
  font-size: $font-size--g;
  line-height: 1.5em; // cannot be any of the variables
  color: $palette--dark-grey;
  word-break: break-word;
}

.ListingCostSummary-key {
  @include typo--body-2;
  display: flex;
  gap: 5px;
  align-items: center;
}

.ListingCostSummary-extension {
  color: $palette--dark-grey;
  font-size: 10px;
  font-weight: $font-weight--bold;
  line-height: 13.5px;
  text-align: right;
}
