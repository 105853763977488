@import "../../styles/variables/colors.scss";
@import "../../styles/variables/spacing.scss";
@import "../../styles/variables/typography.scss";
@import "../../styles/variables/borders.scss";

.ListingSummary {
  display: flex;
  flex-direction: column;
  padding: $spacing--large 10%;
  height: max-content;

  .ListingSummary-imgContainer {
    .CoverImage-image {
      border-radius: $border-radius-base;
    }
  }
}

.ListingSummary-imgContainer {
  margin-bottom: $spacing--small;
  padding-bottom: 66.6666%;
  height: 0;
  position: relative;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
}

.ListingSummary-imgContainer-img {
  &.CoverImage {
    border-radius: $border-radius-base;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }
}

.ListingSummary-title {
  color: black;
  font-family: $primary-font-stack;
  font-weight: $font-weight--bold;
  font-size: $font-size--e;
  line-height: $line-height--small;
}

.ListingSummary-location {
  font-family: $primary-font-stack;
  font-weight: $font-weight--medium;
  font-size: $font-size--f;
  line-height: $line-height--small;
  color: $palette--dark-grey;
}

.ListingSummary-details {
  font-family: $primary-font-stack;
  font-weight: $font-weight--medium;
  font-size: $font-size--f;
  line-height: $line-height--small;
  color: $palette--dark-grey;
}

.ListingSummary-details {
  margin-bottom: $spacing--base * 4;
}

.ListingSummary-details-item:not(:last-child):after {
  content: "•";
  padding: 0 $spacing--base;
}
