@import "../../styles/variables.scss";

.CodeInput {
  display: flex;
  width: 100%;
  column-gap: 5px;
}

.CodeInput-input {
  @include typo--heading-2;
  width: 46px;
  height: 60px;
  border: 1px solid $palette--tint-4-grey;
  background: $palette--tint-1-grey;
  border-radius: 5px;
  text-align: center;
  outline: none;

  &:focus,
  &:active,
  &:hover,
  &:focus-visible {
    border-color: $palette--wunder-purple;
  }

  &:active,
  &:focus {
    background: #f5f1fb;
  }
}

.ErrorIndicator.ErrorIndicator--invalid .CodeInput-input,
.CodeInput--error .CodeInput-input {
  border-color: $palette--indian-red;
  background: $palette--pale-red;
}

.ErrorIndicator.ErrorIndicator--required .CodeInput-input {
  border-color: $color-required;
  background: #742dd90f;
}

.ErrorIndicator.ErrorIndicator--required .ErrorIndicator-message,
.ErrorIndicator.ErrorIndicator--invalid .ErrorIndicator-message {
  position: absolute;
  transform: translateY(3px);
}
