@import "../../styles/media";
@import "../../styles/variables";

.InformationTable {
  width: 100%;
  border-collapse: collapse;
}

.InformationTable-heading {
  @include typo--heading-4;
  text-align: left;
  border-bottom: $spacing--base * 2 solid transparent;
  padding-bottom: $spacing--base * 4;
}

.InformationTable-key {
  @include typo--body-2;
}

.InformationTable-text {
  @include typo--heading-4;
  text-align: right;
}

.InformationTable-text--isGrayed {
  color: $palette--grey;
}

.InformationTable-text--isCrossed {
  text-decoration-line: line-through;
}

.InformationTable-row:not(:first-of-type) {
  .InformationTable-key,
  .InformationTable-text {
    padding-top: $spacing--base * 2;
  }
}

.InformationTable-row:not(:last-of-type) {
  .InformationTable-key,
  .InformationTable-text {
    padding-bottom: $spacing--base * 2;
  }
}

.InformationTable-row:not(:last-of-type) {
  border-bottom: 1px solid $palette--tint-3-grey;
}
